import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="darmerkrankungen" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Chronische Darmerkrankung`}</h1>
      <h3 {...{
        "id": "chronische-darmerkrankung"
      }}>{`Chronische Darmerkrankung`}</h3>
      <p>{`…haben immer eine Vorgeschichte:`}</p>
      <p>{`Ernährungsfehler / Lebensmittelzusatzstoffe, Darminfektionen, Umweltgifte, psychische Dauerbelastungen/Stress, Abführmittel, Cortison-/Antibiotika-Anwendungen, Grunderkrankungen von Magen, Leber, Bauchspeicheldrüse u.a.m. führen häufig zu einer gestörten Darmflora und nachfolgend zu Funktionsstörungen wie Blähungen, Durchfall, Verstopfung, die über lange Zeit meist nicht so wichtig genommen werden.`}</p>
      <p>{`Kann sich die Darmflora nicht regenerieren, kommt es zu Fehlbesiedelungen mit Fremdkeimen (Bakterien, Pilze), die die Darmschleimhaut reizen und neben einer allgemeinen Immunschwäche (s.a. `}<a parentName="p" {...{
          "href": "/beschwerdebilder/immunstaerkung"
        }}>{`Immunstärkung`}</a>{`) zu `}<a parentName="p" {...{
          "href": "/beschwerdebilder/allergien_unvertraeglichkeiten"
        }}>{`Allergien / Unverträglichkeiten`}</a>{` führen können.`}</p>
      <p>{`Hält dieser Zustand längere Zeit an, führt dies zu Schleimhautschädigungen und entzündlichen Darmerkrankungen (Morbus Crohn, Colitis ulcerosa), die schulmedizinisch mit entzündungshemmenden Präparaten behandelt werden.`}</p>
      <p>{`Aus ganzheitlicher Sicht ist hier neben einer individuellen Konstitutionstherapie eine aussagefähige `}<a parentName="p" {...{
          "href": "/diagnoseverfahren/stuhl-labor"
        }}>{`Stuhluntersuchung`}</a>{` mit nachfolgender `}<a parentName="p" {...{
          "href": "/therapien/darmsanierung"
        }}>{`Darmsanierung`}</a>{` und Ernährungsberatung unabdingbar.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      